 const config = {
     API_URL: "https://dashboardapp.easygo.co/api/api/v1",
    // API_URL: "https://easygobackoffice.osc-fr1.scalingo.io/api/v1",
    // API_URL: "http://194.164.60.141/api/api/v1",
    // API_URL: "https://easy-api-gio-production.up.railway.app/api/v1",
    // API_URL: "https://maetvin.online/api/api/v1",
    //  API_URL: "http://127.0.0.1:2094/api/v1",
    //  API_URL: "http://194.164.60.144/api/api/v1",
    // API_URL: "https://easyapi.osc-fr1.scalingo.io/api/v1",
    // API_URL: "https://easyapi.osc-fr1.scalingo.io/api/v1",
    //API_URL:"http://localhost:3005/api/v1",
    // PUBLIC_URL:"https://maetvin.online",
    // API_URL: "http://127.0.0.1:3005/api/v1",
    // API_URL: "https://194.164.60.144/old/api/api/v1",
    // API_URL: "https://test.dashboardapp.easygo.co/old/api/api/v1",
   // API_URL: "https://test.dashboardapp.easygo.co/api/api/v1"
};

export default config;
